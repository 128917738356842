<template>
<v-layout row wrap justify-center align-center class="add-mood-form" gap-xs-3>
  <v-flex xs12>
    <h4 class="starling-h4 StarlingDarkGrey--text">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.title') }}</h4>
  </v-flex>

  <v-flex xs12 :class="[key, 'is-active']" v-for="(item, key) in moodIntensitiesFilter" :key="key">
    <p class="starling-body font-weight-bold primary--text">{{ $t(`app.tools.mood_tracker.tabs.tool.fields.emotions.${key}`) }}</p>

    <slider
      :key="sliderKeys[key].value"
      :value="form.moodIntensities[key].value"
      :min="0"
      :max="10"
      :tick-labels="false"
      @change="catchInput($event, key)">
    </slider>

    <v-layout no-wrap>
      <v-flex xs4 text-left><em class="starling-text font-weight-semi-bold">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.mild') }}</em></v-flex>
      <v-flex xs4 text-center><em class="starling-text font-weight-semi-bold">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.moderate') }}</em></v-flex>
      <v-flex xs4 text-right><em class="starling-text font-weight-semi-bold" style="margin-right:-5px">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.intense') }}</em></v-flex>
    </v-layout>

    <v-layout no-wrap>
      <v-flex xs4 text-left><span class="starling-text font-weight-semi-bold">0</span></v-flex>
      <v-flex xs4 text-right><span class="starling-text font-weight-semi-bold" style="margin-right:-5px">10</span></v-flex>
    </v-layout>
  </v-flex>

  <v-flex xs12 v-if="showDetails">
    <v-form ref="form" v-model="valid">
      <v-layout v-show="showReason" row wrap mb-3>
        <v-flex xs12>
          <h4 class="starling-h4 StarlingDarkGrey--text mb-3">{{ $t('app.tools.mood_tracker.tabs.tool.fields.intensities.reason.title') }}</h4>
          <p class="starling-body mb-3">{{ form.reasonLabel || $t('app.tools.mood_tracker.tabs.tool.fields.intensities.reason.subtitle') }}</p>

          <v-textarea
                  class="no-label mood-reason"
                  v-model="form.reasonText"
                  :rules="rules.reasonTextRules"
                  :counter="1024"
                  persistent-hint
                  outline
                  auto-grow>
          </v-textarea>
        </v-flex>
      </v-layout>

      <v-layout v-if="showDate || showTime" row wrap mb-3>
        <v-flex xs12>
          <h4 class="starling-h4 StarlingDarkGrey--text">{{ $t('app.tools.mood_tracker.tabs.tool.fields.date.title') }}</h4>
        </v-flex>
      </v-layout>

      <v-dialog v-show="showDate"
              ref="dateDialog"
              v-model="dateModalOpen"
              :return-value.sync="form.moodDate"
              persistent
              lazy
              full-width
              width="290px"
              content-class="radius-15">
        <v-text-field
                class="mood-date"
                slot="activator"
                readonly
                :value="formattedDate"
                :rules="rules.required"
                :label="$t('app.tools.mood_tracker.tabs.tool.fields.date.label')"
                :class="{ 'form-modal-open': dateModalOpen, 'mb-3': showTime }"
                prepend-icon="mdi-calendar-blank"
                persistent-hint>
        </v-text-field>
        <v-date-picker
                color="primary"
                :max="maxDate"
                :locale="currentLanguage"
                v-model="form.moodDate"
                scrollable>
          <v-spacer></v-spacer>
          <v-btn flat @click="dateModalOpen = false">{{$t('common.actions.cancel')}}</v-btn>
          <primary-button @click="cleanTime">
            {{$t('common.actions.save')}}
          </primary-button>
        </v-date-picker>
      </v-dialog>

      <v-dialog v-show="showTime"
              ref="timeDialog"
              v-model="timeModalOpen"
              :return-value.sync="form.moodTime"
              persistent
              lazy
              full-width
              width="290px"
              content-class="radius-15">
        <v-text-field
                class="mood-time"
                slot="activator"
                readonly
                :value="formattedTime"
                :label="$t('app.tools.mood_tracker.tabs.tool.fields.time.label')"
                :class="{'form-modal-open': timeModalOpen}"
                prepend-icon="mdi-clock-outline"
                :rules="rules.timeRules"
                persistent-hint>
        </v-text-field>
        <v-time-picker
                color="primary"
                v-if="timeModalOpen"
                :format="timeFormat"
                v-model="form.moodTime"
                :max="maxTime">
          <v-spacer></v-spacer>
          <v-btn flat @click="timeModalOpen = false">{{$t('common.actions.cancel')}}</v-btn>
          <primary-button @click="$refs.timeDialog.save(form.moodTime)">
            {{$t('common.actions.save')}}
          </primary-button>
        </v-time-picker>
      </v-dialog>

    </v-form>
  </v-flex>

  <v-flex xs12>
    <v-layout v-show="showChangeMood" row wrap justify-center align-center mb-3>
      <v-flex xs12 class="text-center">
        <v-btn
          flat round
          :disabled="loading"
          :large="!inline"
          @click="back">
          {{$t('common.actions.change_moods')}}
        </v-btn>
      </v-flex>
    </v-layout>

    <v-layout row wrap justify-center align-center gap-xs-3>
      <v-flex xs12 class="text-center">
        <primary-button id="btn-mood-form-save"
          :disabled="!isFormValid"
          :loading="loading"
          :large="!inline"
          @click="submitAction">
          {{ inline ? $t('app.tools.mood_tracker.actions.save_shift') : $t('common.actions.save')}}
        </primary-button>
      </v-flex>
      <v-flex xs12 class="text-center" v-if="cancellable">
        <v-btn
          flat
          :large="!inline"
          @click="cancel">
          {{$t('common.actions.cancel')}}
        </v-btn>
      </v-flex>
    </v-layout>
  </v-flex>
</v-layout>
</template>

<script>
import { format, isPast, parse } from 'date-fns';
import { MoodTypeEnum } from '@/scripts/store/modules/tools/moods/types';
import Slider from '@/views/components/slider';
import PrimaryButton from '@/views/components/button/primary-button';

const DATE_FORMAT = 'yyyy-MM-dd';
const TIME_FORMAT = 'HH:mm';
const DATETIME_FORMAT = 'yyyy-MM-dd HH:mm';
const TIMESTAMP_FORMAT = 'yyyy-MM-dd HH:mm:ss';

export default {
  name: 'addMoodIntensities',
  components: {
    PrimaryButton,
    Slider,
  },
  props: {
    back: {
      type: Function,
      required: true,
    },
    mood: {
      type: Object,
      required: true,
    },
    loading: {
      type: Boolean,
      required: false,
      default: false,
    },
    cancellable: {
      type: Boolean,
      required: false,
      default: false,
    },
    inline: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    const form = {
      id: this.mood.id || null,
      deleted: this.mood.deleted || false,
      favourite: this.mood.favourite || false,
      moodIntensities: this.mood.moodIntensities || null,
      reasonLabel: this.mood.reasonLabel || null,
      reasonText: this.mood.reasonText || '',
      moodDate: format(new Date(), DATE_FORMAT),
      moodTime: format(new Date().getTime(), 'hh:mm'),
    };

    if (!form.moodIntensities) {
      throw new Error('Moods should be defined.');
    }

    if (this.mood.date) {
      const moodDate = new Date(this.mood.date);
      const moodTime = moodDate.getTime();
      form.moodDate = format(moodDate, DATE_FORMAT);
      const time = format(moodTime, TIME_FORMAT);
      form.moodTime = time !== '23:59' ? time : '';
    }

    return {
      form,
      valid: true,
      rules: {
        required: [ v => !!v || this.$t('common.errors.required') ],
        reasonTextRules: [ v => v.length <= 1024 || this.$t('common.errors.max1024') ],
        timeRules: [ v => this.validTime(v) || '' ],
      },
      sliderKeys: Object.assign({}, form.moodIntensities),
      dateModalOpen: false,
      timeModalOpen: false,
    };
  },
  computed: {
    formattedDate: {
      get() {
        return this.form.moodDate ? format(parse(this.form.moodDate, DATE_FORMAT, new Date()), this.currentLocale.formatLong?.date(), { locale: this.currentLocale }) : null;
      },
      set(newValue) {
      },
    },
    formattedTime: {
      get() {
        const moodDate = this.form.moodDate ? this.form.moodDate : this.maxDate;
        return this.form.moodTime ? format(parse(`${moodDate} ${this.form.moodTime}`, DATETIME_FORMAT, new Date()), this.currentLocale.formatLong?.time({ width: 'short' }), { locale: this.currentLocale }) : null;
      },
      set(newValue) {
      },
    },
    isFormValid() {
      return this.valid &&
        Object.values(this.form.moodIntensities).some(entry => entry.selected) &&
        Object.values(this.form.moodIntensities).filter(entry => entry.selected).every(entry => entry.value !== null);
    },
    maxTime() {
      if (this.form.moodDate === this.maxDate) {
        return format(new Date(), TIME_FORMAT);
      }
      return null;
    },
    maxDate() {
      return format(new Date(), DATE_FORMAT);
    },
    currentLanguage() {
      return this.$store.getters.currentLanguage;
    },
    currentLocale() {
      return this.$store.getters.getCurrentLocale;
    },
    timeFormat() {
      return this.$store.getters.getHourFormat;
    },
    moodIntensitiesFilter() {
      const orderedMoods = {};
      Object.values(MoodTypeEnum).forEach(type => {
        if (this.form.moodIntensities[type] && this.form.moodIntensities[type].selected) {
          orderedMoods[type] = this.form.moodIntensities[type];
        }
      });
      return orderedMoods;
    },
    showChangeMood() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'selector');
    },
    showReason() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'reason');
    },
    showDate() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'date');
    },
    showTime() {
      return !this.mood.visibleFields || this.mood.visibleFields.some(field => field === 'time');
    },
    showDetails() {
      return this.showReason || this.showDate || this.showTime;
    },
  },
  methods: {
    validTime() {
      const moodDate = this.form.moodDate ? this.form.moodDate : this.maxDate;
      return isPast(parse(`${moodDate} ${this.form.moodTime}`, DATETIME_FORMAT, new Date()));
    },
    cleanTime () {
      this.form.moodTime = format((new Date().getTime()), TIME_FORMAT);
      this.$refs.dateDialog.save(this.form.moodDate);
    },
    submitAction() {
      if (this.isFormValid) {
        const mood = Object.assign({}, this.form);
        const date = this.form.moodDate;
        const time = this.form.moodTime ? `${this.form.moodTime}:00` : '23:59:59';
        mood.date = parse(`${date} ${time}`, TIMESTAMP_FORMAT, new Date());
        delete mood.moodTime;
        delete mood.moodDate;
        this.$emit('mood-submit', mood);
      }
    },
    catchInput(value, key) {
      this.$log.debug('Catch input', value, 'for key', key);
      this.form.moodIntensities[key].value = value;
    },
    cancel() {
      this.$router.go(-1);
    },
  },
};
</script>

<style>
.no-label textarea {
  margin-top: 0 !important;
}
.no-label .v-input__control {
  background-color: white;
}
</style>
